import { AccentFlagMapType, LanguageAccentsMapType } from '../../types/types';

export const INITIAL_LANUAGE_ACCENTS_MAP: LanguageAccentsMapType = {
    en: [],
    es: [],
    fr: [],
    de: [],
    it: [],
    nl: [],
    pt: [],
    zh: [],
    ja: [],
    ko: [],
    ar: [],
    hi: []
};

export const ACCENT_FLAGS_MAP: AccentFlagMapType = {
    American: '🇺🇸',
    Australian: '🇦🇺',
    British: '🇬🇧',
    Canadian: '🇨🇦',
    Indian: '🇮🇳',
    Filipino: '🇵🇭',
    China: '🇨🇳',
    Dutch: '🇳🇱',
    French: '🇫🇷',
    German: '🇩🇪',
    Italian: '🇮🇹',
    Japanese: '🇯🇵',
    Korean: '🇰🇷',
    Portuguese: '🇵🇹',
    Spanish: '🇪🇸',
    Swiss: '🇨🇭',
    Saudi: '🇸🇦',
    Austrian: '🇦🇹',
    Brazilian: '🇧🇷',
    Mexican: '🇲🇽'
};
