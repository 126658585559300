import { ALL_PROMO_COOKIES_VALUES, THE_JUICE_30_DAY_PROFESSIONAL_TRIAL_COOKIE_NAME } from '../utils/constants/campaignConstants';

export const getSingleCookie = (cookie: string | undefined, key: string) => {
    let parsedCookie: any = undefined;

    if (cookie) {
        const crumbs: string[] = cookie.split('; ');

        crumbs.forEach((crumb: string) => {
            const crushedCrumb: string[] = crumb.split('=');

            if (crushedCrumb[0] === key) {
                parsedCookie = JSON.parse(crushedCrumb[1]);
            }
        });

        return parsedCookie;
    }
};
export const deleteCookie: Function = () => {
    document.cookie = 'auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // ? Should anonymous_uuid be deleted here? Needs more verification alongside cypress tests
    // document.cookie = 'anonymous_uuid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const getDateInOneWeek = () => {
    const oneWeekInMilliseconds = 1000 * 60 * 60 * 24 * 7;
    return new Date(new Date().getTime() + oneWeekInMilliseconds);
};

export const getDateStringInOneWeek = () => {
    const oneWeekInMilliseconds = 1000 * 60 * 60 * 24 * 7;

    return new Date(new Date().getTime() + oneWeekInMilliseconds).toUTCString();
};

export const getDateStringInFourWeeks = () => {
    const fourWeeksInMilliseconds = 1000 * 60 * 60 * 24 * 7 * 4;
    return new Date(new Date().getTime() + fourWeeksInMilliseconds).toUTCString();
};

export const formatAuthCookie = (cookieBody: Record<string, any>) => {
    return 'auth=' + JSON.stringify(cookieBody) + ';Path=/;expires=' + getDateStringInFourWeeks() + ';';
};

export const checkIfTheJuiceCookieExists = () => {
    return document.cookie.includes(THE_JUICE_30_DAY_PROFESSIONAL_TRIAL_COOKIE_NAME);
};

export const checkIfPromoCookieExists = () => {
    for (const cookieValue of ALL_PROMO_COOKIES_VALUES) {
        if (document.cookie.includes(cookieValue)) {
            return true;
        }
    }
    return false;
};
