import { UnorderedList, VStack, Text } from '@chakra-ui/react';
import { PAYWALLED_FEATURES } from './featureFlagConstants';

// TODO - these aren't current displayed anymore but we'll probably need to reference them in the future to move customers out of these plans
// export const FEATURES_BASIC_LEGACY = [
//     { featureName: '25 audio files/month' },
//     { featureName: 'Intros and outros', paywalledFeature: PAYWALLED_FEATURES.INTROS_AND_OUTROS },
//     { featureName: 'RSS feed importer' },
//     { featureName: 'Embed player', paywalledFeature: PAYWALLED_FEATURES.EMBED_PLAYER },
//     { featureName: 'Podcast distribution', paywalledFeature: PAYWALLED_FEATURES.PODCAST_DISTRIBUTION },
//     { featureName: 'Youtube distribution', paywalledFeature: PAYWALLED_FEATURES.YOUTUBE_DISTRIBUTION },
//     { featureName: 'Player customization', paywalledFeature: PAYWALLED_FEATURES.PLAYER_CUSTOMIZATION },
//     {
//         featureName: 'Lexicon',
//         paywalledFeature: PAYWALLED_FEATURES.LEXICON,
//         tooltipText: 'Adjust and save the correct pronunciation of specific words'
//     },
//     { featureName: 'Basic support' },
//     { featureName: 'Onboarding session (30 minutes)' },
//     { featureName: 'Basic analytics', paywalledFeature: PAYWALLED_FEATURES.BASIC_ANALYTICS }
// ];

// export const FEATURES_PRO_LEGACY = [
//     { featureName: 'Everything in “Basic”' },
//     { featureName: '250 audio files/month' },
//     { featureName: 'Pro support' },
//     { featureName: 'Onboarding session (1 hour)' },
//     { featureName: 'Pro analytics', paywalledFeature: PAYWALLED_FEATURES.PRO_ANALYTICS }
// ];

export const FEATURES_FREE = [
    { featureName: '20 credits' },
    { featureName: '90+ Standard Voices' },
    { featureName: '20+ Premium Voices' },
    { featureName: '10+ Languages' },
    { featureName: 'Pre-made script templates', paywalledFeature: PAYWALLED_FEATURES.AI_AUDIO },
    { featureName: 'Audiogram creator' },
    { featureName: 'Embeddable audio player (iframe)' },
    { featureName: 'Shareable audio landing page', paywalledFeature: PAYWALLED_FEATURES.STUDIO_DISTRIBUTION_TAB_SHAREABLE_LINK },
    { featureName: 'oEmbed URL' }
];

export const FEATURES_BASIC = [
    { featureName: 'Everything in "Free"' },
    {
        featureName: (
            <VStack alignItems={'flex-start'}>
                <Text>50 credits/month</Text>
                <Text as="i">for example: you could individually create five 10-minute standard voice word-for-word narrations.</Text>
            </VStack>
        )
    },
    { featureName: 'Recording Upload', paywalledFeature: PAYWALLED_FEATURES.UPLOAD_AUDIO },
    {
        featureName: 'Pronunciation Library',
        tooltipText: 'Adjust and save the correct pronunciation of specific words',
        paywalledFeature: PAYWALLED_FEATURES.PRONUNCIATION_LIBRARY_PAGE
    },
    {
        featureName: 'HubSpot integration',
        paywalledFeature: PAYWALLED_FEATURES.HUBSPOT_INTEGRATION
    },
    {
        featureName: 'RSS integration',
        paywalledFeature: PAYWALLED_FEATURES.RSS_INTEGRATION
    },
    { featureName: 'Podcast hosting and distribution', paywalledFeature: PAYWALLED_FEATURES.PODCAST_DISTRIBUTION },
    { featureName: 'Youtube distribution', paywalledFeature: PAYWALLED_FEATURES.YOUTUBE_DISTRIBUTION },
    { featureName: 'Customizable audio player', paywalledFeature: PAYWALLED_FEATURES.EMBED_PLAYER },
    { featureName: 'Customizable shareable audio landing page', paywalledFeature: PAYWALLED_FEATURES.SHARE_PAGE_CUSTOMIZATION },
    { featureName: 'Analytics', paywalledFeature: PAYWALLED_FEATURES.ANALYTICS },
    { featureName: 'Dynamic embeddable audio player (iframe)' },
    { featureName: 'Downloadable audio (mp3)', paywalledFeature: PAYWALLED_FEATURES.DOWNLOAD_AUDIO },
    { featureName: 'Script editing' },
    { featureName: 'Background music' },
    { featureName: 'Sound effects' },
    { featureName: 'Adauris audio watermark removal' },
    { featureName: 'Workflow integrations' }
];

export const FEATURES_PRO = [
    { featureName: 'Everything in "Basic"' },
    {
        featureName: (
            <VStack alignItems={'flex-start'}>
                <Text>200 credits/month</Text>
                <Text as="i">
                    for example: you could automate the monthly production of five bespoke 10-minute premium voice conversational podcast episodes
                    that use your sonic identity and custom AI hosts.
                </Text>
            </VStack>
        )
    },
    {
        featureName: (
            <VStack alignItems={'flex-start'}>
                <Text>Onboarding package (typically $4,500):</Text>
                <UnorderedList>
                    <li>Audio Strategy Proposal</li>
                    <li>Sonic Identity</li>
                    <li>Custom intro/outro tracks</li>
                    <li>Podcast items (cover art, SEO optimized show description, and show title)</li>
                </UnorderedList>
            </VStack>
        )
    },
    { featureName: '2 custom AI hosts', paywalledFeature: PAYWALLED_FEATURES.BRANDING },
    { featureName: '1 custom script template' },
    { featureName: '1 hr onboarding call and automation set-up' },
    { featureName: '1 hr monthly customer support' },
    { featureName: 'Priority QA and Slack Channel' }
];

export const FEATURES_ENTERPRISE = [
    { featureName: 'Everything in "Professional"' },
    { featureName: 'Custom Credit Count' },
    { featureName: 'Custom workflow integration' },
    { featureName: 'Custom audio output' },
    { featureName: 'One of a kind sonic logo (music and/or 3-5 second sound bite)' },
    { featureName: 'Custom voices' },
    { featureName: 'Expanded music selection' },
    { featureName: 'SEO indexed audio catalogue page' },
    { featureName: 'Language translations' },
    { featureName: 'Accent localizations' },
    { featureName: 'API Access' },
    { featureName: 'Custom master service agreement' },
    { featureName: 'Advanced security features' },
    { featureName: 'Audit logs on demand' },
    { featureName: 'MFA (Multi-factor auth)' },
    { featureName: 'SLA (99.9% uptime)' },
    { featureName: 'OP3 podcast analytics' }
];

export const FEATURES_ONBOARDING_PACKAGE = [
    { featureName: 'Audio Strategy Proposal' },
    { featureName: 'Sonic Identity' },
    { featureName: 'Custom intro/outro tracks' },
    { featureName: 'Podcast items (cover art, SEO optimized show description, and show title)' },
    {
        featureName: (
            <VStack alignItems={'flex-start'}>
                <Text>Optional add-on items:</Text>
                <UnorderedList>
                    <li>Custom scripting parameters (single input source to consistent output)</li>
                    <li>Bespoke background music and sonic logo tracks</li>
                    <li>Audio Promotion and Launch strategy (questions to understand the client’s existing marketing channels + launch proposal)</li>
                </UnorderedList>
            </VStack>
        )
    }
];

export const ESTIMATED_CHARS_PER_MINUTE = 1000;
export const CREDITS_PER_MINUTE = 1;

export const CREDITS_PER_CHAR = CREDITS_PER_MINUTE / ESTIMATED_CHARS_PER_MINUTE;

export enum CREDIT_WEIGHT {
    STANDARD = 1,
    PREMIUM_VOICE = 2,
    SCRIPTING = 2
}

export const LOWEST_GROUP_VERSION_ON_CREDIT_SYSTEM = 3;
